import { Grid } from "@mui/joy"
import { LinkCard } from "../components/LinkCard"
import { LinkData } from "../components/Types"
import YouTubeMusic from "../images/ytmusic.webp";
import SiriusXM from "../images/sirius.webp";
import Spotify from "../images/spotify.webp";
import Pandora from "../images/pandora.webp";

const sirius: LinkData = {
    name: "SiriusXM",
    url: "https://player.siriusxm.com/home/discover",
    image: SiriusXM,
}

const ytmusic: LinkData = {
    name: "YouTube Music",
    url: "https://music.youtube.com/",
    image: YouTubeMusic
}

const spotify: LinkData = {
    name: "Spotify",
    url: "https://open.spotify.com/",
    image: Spotify
}

const pandora: LinkData = {
    name: "Pandora",
    url: "https://pandora.com/",
    image: Pandora
}

export function Hear() {
    return (
        <Grid container spacing={2} sx={{ padding: 2 }} justifyContent={'center'}>
            <Grid>
                <LinkCard data={sirius} />
            </Grid>
            <Grid>
                <LinkCard data={spotify} />
            </Grid>
            <Grid>
                <LinkCard data={pandora} />
            </Grid>
            <Grid>
                <LinkCard data={ytmusic} />
            </Grid>
        </Grid>
    )
}