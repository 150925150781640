import { Grid } from "@mui/joy"
import { LinkCard } from "../components/LinkCard"
import { LinkData } from "../components/Types"
import AirConsole from "../images/airconsole.webp";
import Retro from "../images/retrogames.webp";
import Crazy from "../images/crazy.webp";
import Luna from "../images/luna.webp";

const airconsole: LinkData = {
    name: "AirConsole",
    url: "https://www.airconsole.com/",
    image: AirConsole
}

const crazy: LinkData = {
    name: "Crazy Games",
    url: "https://www.crazygames.com/t/controller",
    image: Crazy
}

const luna: LinkData = {
    name: "Luna",
    url: "https://luna.amazon.com/",
    image: Luna
}

const retro: LinkData = {
    name: "RetroGames",
    url: "https://www.retrogames.cc/",
    image: Retro
}

export function Play() {
    return (
        <Grid container spacing={2} sx={{ padding: 2 }} justifyContent={'center'}>
            <Grid>
                <LinkCard data={airconsole} />
            </Grid>
            <Grid>
                <LinkCard data={crazy} />
            </Grid>
            <Grid>
                <LinkCard data={luna} />
            </Grid>
            <Grid>
                <LinkCard data={retro} />
            </Grid>
        </Grid>
    )
}