import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { ProductData } from "./Types";
import { formatCurrency } from '../utils/formatCurrency';
import { CardOverflow } from '@mui/joy';

export function ProductCard({ data }: { data: ProductData }) {
    return (
        <Card variant={"plain"} sx={{ width: 225, borderRadius: 14, bgcolor: 'neutral.50' }}>
            <CardOverflow sx={{ bgcolor: 'neutral.200' }}>
                <CardContent>
                    <Typography level="h2" fontSize="md" textColor={'neutral.900'} sx={{ mb: 0.5 }}>
                        {data.title}
                    </Typography>
                    <Typography level="body2" textColor={'neutral.900'}>{data.style}</Typography>
                </CardContent>
            </CardOverflow>
            <AspectRatio minHeight="120px" maxHeight="200px">
                <img
                    src={data.image}
                    loading="lazy"
                    alt=""
                />
            </AspectRatio>
            <CardContent orientation="horizontal">
                <div>
                    {/* <Typography level="body3">Total price:</Typography> */}
                    <Typography fontSize="lg" fontWeight="lg" textColor={'neutral.900'}>
                        {formatCurrency(data.price)}
                    </Typography>
                </div>
                <Button
                    variant="solid"
                    component="a"
                    href={data.url}
                    size="md"
                    color="primary"
                    aria-label={data.title + " " + data.style}
                    sx={{ ml: 'auto', fontWeight: 600 }}
                >
                    Buy
                </Button>
            </CardContent>
        </Card>
    );
}
