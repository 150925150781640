import { Grid } from "@mui/joy";
import { LinkCard } from "../components/LinkCard";
import { LinkData } from "../components/Types";
import Code from "../images/vscode.webp";
import Docs from "../images/docs.webp";
import Office from "../images/office.webp";

const office: LinkData = {
    name: "Office",
    url: "https://www.office.com/",
    image: Office
}

const docs: LinkData = {
    name: "Google Docs",
    url: "https://docs.google.com/",
    image: Docs
}

const code: LinkData = {
    name: "VS Code",
    url: "https://vscode.dev/",
    image: Code
}
export function Work() {
    return (
        <Grid container spacing={2} sx={{ padding: 2 }} justifyContent={'center'}>
            <Grid>
                <LinkCard data={docs} />
            </Grid>
            <Grid>
                <LinkCard data={office} />
            </Grid>
            <Grid>
                <LinkCard data={code} />
            </Grid>
        </Grid>
    )
}