import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import { LinkData } from "./Types";
import { Link } from '@mui/joy';

export function LinkCard({ data }: { data: LinkData }) {
    return (
        <Card variant='plain' sx={{ width: 150, height: 200, minHeight: 150, background: 'none', boxShadow: 'none' }}>
            <img
                src={data.image}
                loading="lazy"
                alt={data.name}
                style={{ borderRadius: 30, maxHeight: 150 }}
            />
            <Typography
                level="body1"
                fontSize="md"
                textAlign={'center'}
                textColor={'white'}
            >
                <Link
                    overlay
                    href={data.url}
                    textColor="inherit"
                    underline="none"
                >
                    {data.name}
                </Link>
            </Typography>
        </Card>
    );
}
