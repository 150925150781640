import { Grid } from "@mui/joy"
import { LinkCard } from "../components/LinkCard"
import { LinkData } from "../components/Types"
import Paramount from "../images/paramount.webp";
import YouTubeTV from "../images/youtube-tv.webp";
import Plex from "../images/plex.webp";
import ESPN from "../images/espn.webp";
import Max from "../images/hbomax.webp";
import Peacock from "../images/peacock.webp";
import Tubi from "../images/tubi.webp";
import Fubo from "../images/fubo.webp";
import Crave from "../images/crave.webp";
import Twitch from "../images/twitch.webp";
import Hulu from "../images/hulu.webp";
import Apple from "../images/apple.webp";
import CW from "../images/cw.webp";
import Disney from "../images/disney.webp";
import Prime from "../images/prime.webp";
import Showtime from "../images/showtime.jpg";
import YouTube from "../images/youtube.webp";

const twitch: LinkData = {
    name: "Twitch",
    url: "https://www.twitch.tv",
    image: Twitch
}

const crave: LinkData = {
    name: "Crave",
    url: "https://www.crave.ca/en",
    image: Crave
}

const fubo: LinkData = {
    name: "Fubo",
    url: "https://fubo.tv",
    image: Fubo
}

const tubi: LinkData = {
    name: "tubi",
    url: "https://tubitv.com/home",
    image: Tubi
}

const peacock: LinkData = {
    name: "Peacock",
    url: "https://www.peacocktv.com",
    image: Peacock
}

const max: LinkData = {
    name: "Max",
    url: "https://play.max.com/",
    image: Max
}

const espn: LinkData = {
    name: "ESPN",
    url: "https://www.espn.com/watch",
    image: ESPN
}

const plex: LinkData = {
    name: "Plex",
    url: "https://app.plex.tv/",
    image: Plex
}

const paramount: LinkData = {
    name: "Paramount+",
    url: "https://paramountplus.com",
    image: Paramount
}

const hulu: LinkData = {
    name: "Hulu",
    url: "https://www.hulu.com",
    image: Hulu
}

const yttv: LinkData = {
    name: "YouTube TV",
    url: "https://tv.youtube.com",
    image: YouTubeTV
}

const apple: LinkData = {
    name: "Apple TV+",
    url: "https://tv.apple.com/",
    image: Apple
}

const cw: LinkData = {
    name: "CW",
    url: "https://www.cwtv.com/",
    image: CW
}

const disney: LinkData = {
    name: "Disney+",
    url: "https://www.disneyplus.com/",
    image: Disney
}

const prime: LinkData = {
    name: "Prime",
    url: "https://www.amazon.com/gp/video/storefront",
    image: Prime
}

const showtime: LinkData = {
    name: "Showtime",
    url: "https://www.sho.com/",
    image: Showtime
}

const yt: LinkData = {
    name: "YouTube",
    url: "https://www.youtube.com/",
    image: YouTube
}

export function See() {
    return (
        <Grid container spacing={2} sx={{ padding: 2 }} justifyContent={'center'}>
            <Grid>
                <LinkCard data={apple} />
            </Grid>
            <Grid>
                <LinkCard data={crave} />
            </Grid>
            <Grid>
                <LinkCard data={cw} />
            </Grid>
            <Grid>
                <LinkCard data={disney} />
            </Grid>
            <Grid>
                <LinkCard data={hulu} />
            </Grid>
            <Grid>
                <LinkCard data={espn} />
            </Grid>
            <Grid>
                <LinkCard data={fubo} />
            </Grid>
            <Grid>
                <LinkCard data={max} />
            </Grid>
            <Grid>
                <LinkCard data={paramount} />
            </Grid>
            <Grid>
                <LinkCard data={peacock} />
            </Grid>
            <Grid>
                <LinkCard data={plex} />
            </Grid>
            <Grid>
                <LinkCard data={prime} />
            </Grid>
            <Grid>
                <LinkCard data={showtime} />
            </Grid>
            <Grid>
                <LinkCard data={tubi} />
            </Grid>
            <Grid>
                <LinkCard data={twitch} />
            </Grid>
            <Grid>
                <LinkCard data={yt} />
            </Grid>
            <Grid>
                <LinkCard data={yttv} />
            </Grid>
        </Grid>

    )
}