import { Grid } from "@mui/joy";
import { LinkCard } from "../components/LinkCard";
import { LinkData } from "../components/Types";
import GoogleMaps from "../images/gmaps.webp";
import Google from "../images/google.webp";
import Waze from "../images/waze.webp";

const waze: LinkData = {
    name: "Waze",
    url: "https://www.waze.com",
    image: Waze
}

const googlemaps: LinkData = {
    name: "Google Maps",
    url: "https://www.google.com/maps",
    image: GoogleMaps
}

const google: LinkData = {
    name: "Google",
    url: "https://www.google.com/",
    image: Google
}

export function Find() {
    return (
        <Grid container spacing={2} sx={{ padding: 2 }} justifyContent={'center'}>
            <Grid>
                <LinkCard data={waze} />
            </Grid>
            <Grid>
                <LinkCard data={google} />
            </Grid>
            <Grid>
                <LinkCard data={googlemaps} />
            </Grid>
        </Grid>
    )
}