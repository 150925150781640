import { Link, ModalDialog, Typography, AspectRatio, Card, CardContent, CardOverflow } from "@mui/joy";
import qr from "../images/qr.webp";

export function ReferralModal() {
    return (
        <ModalDialog
            color='neutral'
            variant='solid'
            size='lg'
            sx={{ padding: 0 }}
        >
            <Card variant="plain" sx={{ width: 320, bgcolor: "neutral.500" }}>
                <CardOverflow>
                    <AspectRatio ratio="1">
                        <img
                            src={qr}
                            alt="Referral QR code"
                            loading="lazy"
                        />
                    </AspectRatio>
                </CardOverflow>
                <CardContent sx={{}}>
                    <Typography level="h2" fontSize="md">
                        Scan the QR code for our referral link
                    </Typography>
                    <Typography level="body1" sx={{ mt: 0.5, }}>
                        Or click <Link variant="solid" href="https://ts.la/jason27095">here</Link>
                    </Typography>
                </CardContent>
            </Card>
        </ModalDialog>
    )
}