import { Grid } from "@mui/joy"
import { LinkCard } from "../components/LinkCard"
import { LinkData } from "../components/Types"
import Facebook from '../images/Facebook.webp'
import Reddit from '../images/reddit.webp'

const fb: LinkData = {
    name: "Facebook",
    url: "https://www.facebook.com/",
    image: Facebook
}

const reddit: LinkData = {
    name: "Reddit",
    url: "https://www.reddit.com/",
    image: Reddit
}

export function Socialize() {
    return (
        <Grid container spacing={2} sx={{ padding: 2 }} justifyContent={'center'}>
            <Grid>
                <LinkCard data={fb} />
            </Grid>
            <Grid>
                <LinkCard data={reddit} />
            </Grid>
        </Grid>

    )
}