import { useState } from 'react';
import { Button, CssVarsProvider, Grid, Modal, Sheet, Typography } from '@mui/joy';
import { useEventListener, useLocalStorage, useIsomorphicLayoutEffect } from 'usehooks-ts'
import './App.css';
import Theme from "./Theme";
import { Header } from './components/Header';
import { SuggestForm } from "./components/SuggestForm";
import { ReferralModal } from './components/ReferralModal';
import { URLModal } from './components/URLModal';
import { SectionHeader } from './components/SectionHeader';
import { WindowSize } from "./components/Types";

import { See } from './sections/See';
import { Hear } from './sections/Hear';
import { Eat } from './sections/Eat';
import { Find } from './sections/Find';
import { Connect } from './sections/Connect';
import { Socialize } from './sections/Socialize';
import { Work } from './sections/Work';
import { Play } from './sections/Play';
import { Shop } from './sections/Shop';
import { Relax } from './sections/Relax';

import { Warning, SportsEsports } from '@mui/icons-material';

function App() {
    const [showQr, setShowQr] = useState(false)
    const [showSuggest, setShowSuggest] = useState(false)
    const [showUrl, setShowUrl] = useState(false)
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: 0,
        height: 0,
    })
    const [customItems, setCustomItems] = useLocalStorage('items', {})

    const handleSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }
    useEventListener('resize', handleSize)
    useIsomorphicLayoutEffect(() => {
        handleSize()
    }, [])

    const clickReferral = () => {
        setShowQr(true)
    }

    const clickSuggest = () => {
        setShowSuggest(true)
    }

    const closeSuggest = () => {
        setShowSuggest(false)
    }

    const clickFullscreen = () => {
        setShowUrl(true)
    }

    return (
        <Sheet sx={{
            backgroundColor: "black",
            marginX: 1

        }}>
            <CssVarsProvider
                defaultMode='dark'
                theme={Theme}
            >
                <Modal
                    open={showSuggest}
                    onClose={() => setShowSuggest(false)}
                >
                    <SuggestForm close={closeSuggest} />
                </Modal>
                <Modal
                    open={showQr}
                    onClose={() => setShowQr(false)}
                >
                    <ReferralModal />
                </Modal>
                <Modal
                    open={showUrl}
                    onClose={() => setShowUrl(false)}

                >
                    <URLModal size={windowSize} />
                </Modal>
                <Header referral={clickReferral} showUrl={clickFullscreen} />
                {/* <SectionHeader title="Custom" /> */}
                {/* <Custom /> */}
                <SectionHeader title="See" />
                <See />
                <SectionHeader title="Hear" />
                <Hear />
                <SectionHeader title="Taste" />
                <Eat />
                <SectionHeader title="Find" />
                <Find />
                <SectionHeader title="Socialize" />
                <Socialize />
                <SectionHeader title="Connect" subtitle='Listen Only' warning icon={<Warning />} />
                <Connect />
                <SectionHeader title="Work" />
                <Work />
                <SectionHeader title="Play" subtitle='Works with controllers' icon={<SportsEsports />} />
                <Play />
                <SectionHeader title="Relax" />
                <Relax />
                <SectionHeader title="Shop" />
                <Shop />
                <Sheet sx={{
                    flexGrow: 1,
                    bottom: 10,
                    width: "100%",
                    marginX: "auto",
                    paddingTop: 1,
                    backgroundColor: "black",
                    // borderTop: 2,
                    borderColor: "neutral.500",
                }}>
                    <SectionHeader title="Improve" />
                    <Grid container
                        paddingTop={3}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"

                    >
                        <Grid>
                            <Button
                                onClick={clickSuggest}
                            >Suggest a website</Button>
                            <Typography level='body2'>
                                Current Size: {windowSize.width} x {windowSize.height}
                            </Typography>
                        </Grid>
                    </Grid>
                </Sheet>
            </CssVarsProvider>
        </Sheet >
    );
}

export default App;
