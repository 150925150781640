import { Grid } from "@mui/joy"
import { LinkCard } from "../components/LinkCard"
import { LinkData } from "../components/Types"
import Teams from '../images/teams.webp'
import Zoom from '../images/zoom.webp'

const teams: LinkData = {
    name: "Teams",
    url: "https://teams.microsoft.com/",
    image: Teams
}

const zoom: LinkData = {
    name: "Zoom",
    url: "https://join.zoom.us",
    image: Zoom
}

export function Connect() {
    return (
        <Grid container spacing={2} sx={{ padding: 2 }} justifyContent={'center'}>
            <Grid>
                <LinkCard data={teams} />
            </Grid>
            <Grid>
                <LinkCard data={zoom} />
            </Grid>
        </Grid>

    )
}