import { Box, Button, Input, ModalDialog, Typography } from "@mui/joy";
import { useState } from "react";
import { WindowSize } from "../components/Types";


export function URLModal({ size }: { size: WindowSize }) {
    const [url, setUrl] = useState("teslal.ink")

    const updateUrl = (input: string) => {
        input = input.replace(/^https:\/\//, '')
        input = input.replace(/^http:\/\//, '')
        setUrl(input)
    }

    const redirect = () => {
        if (size.width > 1180) {
            window.location.assign(`https://${url}`)
        } else {
            window.location.assign(`https://www.youtube.com/redirect?q=https://${url}`)
        }
    }

    return (
        <ModalDialog
            color="neutral"
            variant="plain"
            sx={{
                py: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
        >
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    redirect()
                }}
            >
                <Typography level="body1">Enter a website to open in fullscreen mode</Typography>
                {size.width <= 1180 &&
                    <Typography level="body2" gutterBottom>Select "GO TO SITE" on the next screen</Typography>
                }
                <Input
                    placeholder={url}
                    value={url}
                    onChange={(e) => { updateUrl(e.target.value) }}
                    sx={{ marginY: 2, width: 400 }}
                    startDecorator={
                        <Box>
                            <Typography variant="soft" sx={{ bgcolor: "neutral.900", color: "neutral.50" }}>https://</Typography>
                        </Box>
                    }
                />
                <Button type="submit">Go!</Button>

            </form >
        </ModalDialog >
    )
}