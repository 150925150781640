import { Grid } from "@mui/joy"
import { LinkCard } from "../components/LinkCard"
import { LinkData } from "../components/Types"
import Starbucks from "../images/starbucks.webp";
import Wendys from "../images/wendys.webp";


const wendys: LinkData = {
    name: "Wendy's",
    url: "https://order.wendys.com/categories?site=menu&lang=en_US",
    image: Wendys
}

// const dunkin: LinkData = {
//     name: "Dunkin",
//     url: "https://order.wendys.com/categories?site=menu&lang=en_US",
//     image: Dunkin
// }

const starbucks: LinkData = {
    name: "Starbucks",
    url: "https://www.starbucks.com/menu",
    image: Starbucks
}

export function Eat() {
    return (
        <Grid container spacing={2} sx={{ padding: 2 }} justifyContent={'center'}>
            <Grid>
                <LinkCard data={starbucks} />
            </Grid>
            <Grid>
                <LinkCard data={wendys} />
            </Grid>
        </Grid>
    )
}