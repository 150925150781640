import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import { AspectRatio, CardOverflow } from '@mui/joy';

export default function VideoCard({ id, title }: { id: string, title: string }) {

    const embed = `https://www.youtube.com/embed/${id}?autoplay=1&mute=1&allowfullscreen=1`
    return (

        <Card sx={{
            bgcolor: 'neutral.50',
            boxShadow: "none",
            borderRadius: 20,
            width: 270,
            height: 165,
            margin: 0,
            padding: 0,
            flexGrow: 0,
            overflow: "hidden"
        }}>
            <CardOverflow>
                <CardContent >
                    <AspectRatio>
                        <iframe
                            id={id}
                            title={title}
                            width="230"
                            height="130"
                            src={embed}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </AspectRatio>
                </CardContent>
            </CardOverflow>
        </Card >
    );
}