import { Grid } from "@mui/joy";
import VideoCard from "../components/VideoCard";


export function Relax() {
    return (
        <Grid container spacing={2} sx={{ padding: 2 }} justifyContent={'center'}>
            <Grid>
                <VideoCard id="dyJIBPwNZ50" title="Ambient Vibes | Winter" />
            </Grid>
            <Grid>
                <VideoCard id="wUd_8uwFoVc" title="Ambient Vibes | Autumn" />
            </Grid>
            <Grid>
                <VideoCard id="ej749ikvMEY" title="Ambient Vibes | Summer" />
            </Grid>
        </Grid>
    )
}