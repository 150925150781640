import { Box, Button, CircularProgress, Input, ModalDialog, Sheet, Textarea, Typography } from "@mui/joy"
import { useState } from "react"


export function SuggestForm({ close }: { close: () => void }) {
    const [suggestion, setSuggestion] = useState("")
    const [submitting, setSubmitting] = useState(false)
    const [buttonText, setButtonText] = useState("Submit")
    const submit = () => {
        setSubmitting(true)
        const data =
        {
            "username": "TeslaLink",
            "avatar_url": "https://teslal.ink/static/media/logo-wide-305x170.8c24574cf754cf594c79.png",
            "content": `New suggestion! ${suggestion}`
        }
        fetch("https://discord.com/api/webhooks/1125931055770255551/HtqImff-yW5F1FWNJXsO4kGxp2TjcBtSWuM1aSJmlmSheO2Acg9SMMynAh0qBEyESGog", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                if (response.ok) {
                    setSubmitting(false)
                    setButtonText("Success!")
                    setTimeout(() => {
                        close()
                    }, 2000);
                }
            })
            .catch(error => console.error(error));

    }

    return (
        <ModalDialog
            color="neutral"
            variant="plain"
            sx={{
                py: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
        >
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    submit()
                }}
            >
                <Textarea
                    placeholder="Suggest a website or improvement"
                    minRows={4}
                    onChange={(e) => { setSuggestion(e.target.value) }}
                    endDecorator={
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 'var(--Textarea-paddingBlock)',
                                pt: 'var(--Textarea-paddingBlock)',
                                borderTop: '1px solid',
                                borderColor: 'divider',
                                flex: 'auto',
                            }}
                        >
                            {submitting ? <CircularProgress /> :
                                <Button type="submit">{buttonText}</Button>
                            }
                        </Box>
                    }
                    sx={{
                        minWidth: 300,
                    }}
                />
            </form >
        </ModalDialog >
    )
}