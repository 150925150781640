import { Link, Grid, Sheet, Typography, Button, Alert, ButtonGroup } from "@mui/joy";
import logo from "../images/logo-wide-305x170.webp";
import { useState } from "react";

export function Header({ referral, showUrl }: { referral: () => void, showUrl: () => void }) {
    const [hackText, setHackText] = useState(false)

    const toggleHackText = () => {
        setHackText(!hackText)
    }
    return (
        <Sheet
            sx={{
                backgroundColor: "black",
                borderColor: "neutral.500",
                marginBottom: 2,
            }}
        >
            <Grid container
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Grid xs={3}>
                    <img
                        src={logo}
                        height={100}
                        alt="Tesla Link logo" />
                </Grid>
                <Grid xs={6}>
                    <Typography
                        level="body1"
                        textColor={"neutral.50"}
                        textAlign={"center"}
                        justifyContent={"center"}
                        gutterBottom
                    >
                        Want to support this site? Use our <Link onClick={referral}>referral link</Link>
                    </Typography>
                </Grid>
                <Grid xs={3}>
                    <Grid container
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"flex-start"}
                    >
                        <ButtonGroup variant="plain" aria-label="fullscreen buttons">
                            <Button onClick={showUrl} size="lg" variant="solid" color="primary">Fullscreen</Button>
                            <Button onClick={toggleHackText} variant="solid" color="info">More info</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                {hackText &&
                    <Alert>Tesla locks down the browser. Certain sites (like YouTube) automatically trigger fullscreen mode. YouTube has a redirect feature, so we can navigate to YouTube to force the browser to go fullscreen, then redirect wherever we want - simply follow the "GO TO SITE" link after clicking Fullscreen.</Alert>
                }
            </Grid>
        </Sheet>
    )

}