import { Grid, Typography } from "@mui/joy";
import { ReactNode } from "react";

export function SectionHeader({ title, subtitle, warning, icon }: { title: string, subtitle?: string, warning?: boolean, icon?: ReactNode }) {

    return (
        <Grid container
            xs={12}
            sx={{ flexGrow: 1 }}
            justifyContent="space-around"
            alignItems="center"

        >
            <hr className="divider" />
            <Typography textColor={"neutral.50"}>{title}</Typography>
            <hr className="divider" />
            {
                subtitle &&
                <Grid xs={12} textAlign={"center"}>
                    <Typography level="body2" justifyContent="center" textColor={warning ? "warning.300" : "neutral.100"} startDecorator={icon}>{subtitle}</Typography>
                </Grid>
            }
        </Grid>
    )
}