import { Grid } from "@mui/joy"
import { ProductCard } from "../components/ProductCard"
import { ProductData } from "../components/Types"
import Yoke from "../images/tesla-yoke.jpeg";
import FireSafety from "../images/FireSafety.png";
import ModelY from "../images/model-y.webp";
import USB from "../images/usb.jpg";
import Keyholder from "../images/keyholder.jpeg";
import Screen from "../images/screen.webp"

const keyholder: ProductData = {
    title: "Key Card Holder",
    style: "Multiple Designs available",
    price: 30,
    image: Keyholder,
    url: 'https://www.etsy.com/listing/1520129127/tesla-key-card-holder'
}

const yoke: ProductData = {
    title: "Tesla Sticker",
    style: "Yoke",
    price: 5,
    image: Yoke,
    url: 'https://www.etsy.com/listing/1505804770/tesla-yoke-sticker'
}

const modely: ProductData = {
    title: "Tesla Sticker",
    style: "Model Y",
    price: 5,
    image: ModelY,
    url: 'https://www.etsy.com/listing/1520048569/model-y-sticker'
}

const fireSticker: ProductData = {
    title: "EV/ESS Safety Sticker",
    style: "EV/ESS",
    price: 10,
    image: FireSafety,
    url: 'https://jnjdesignsshop.etsy.com/listing/1710691701/ev-and-battery-backup-fire-safety'
}

const usb: ProductData = {
    title: "Tesla Bluetooth Adapter",
    style: "Connect BT Controllers",
    price: 20,
    image: USB,
    url: 'https://www.amazon.com/dp/B09M8CVMYF?&_encoding=UTF8&tag=jjd09-20&linkCode=ur2&linkId=3e2322b55c230edea48503c7c2b45ca7&camp=1789&creative=9325'
}

const screen: ProductData = {
    title: "Tesla Screen Protector",
    style: "Also Reduces Fingerprints",
    price: 24,
    image: Screen,
    url: 'https://amzn.to/3JQFdvp'
}

export function Shop() {
    return (
        <Grid container
            spacing={2}
            direction={'row'}
            justifyContent={'center'}
            sx={{ padding: 2, marginBottom: 2 }}
        >
            <Grid>
                <ProductCard data={keyholder} />
            </Grid>
            <Grid>
                <ProductCard data={yoke} />
            </Grid>
            <Grid>
                <ProductCard data={modely} />
            </Grid>
            <Grid>
                <ProductCard data={fireSticker} />
            </Grid>
            {/* <Grid>
                <ProductCard data={screen} />
            </Grid> */}
        </Grid>
    )
}