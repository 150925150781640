
import { extendTheme } from '@mui/joy/styles';


declare module '@mui/joy/styles' {
    // No custom tokens found, you can skip the theme augmentation.
}


const theme = extendTheme({
    "colorSchemes": {
        "light": {
            "palette": {}
        },
        "dark": {
            "palette": {
                "danger": {
                    "500": "#ce7e00"
                },
                "neutral": {
                    "50": "#fafafa",
                    "100": "#f2f2f2",
                    "200": "#aeb0b2",
                    "300": "#717273",
                    "500": "#818181",
                    "900": "#212121"
                },
                "primary": {
                    "500": "#cc0000"
                },
                "info": {
                    "500": "#3990ff"
                }
            },

        }
    },
    "fontFamily": {
        "body": "Gotham SSm",
        "display": "Gotham SSm",
    },
    "components": {
        "JoyDivider": {
            "styleOverrides": {
                root: {
                    color: "white"
                }
            }
        }
    }
})

export default theme;